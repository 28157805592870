import React, { useState } from 'react';
import { Link, graphql } from "gatsby"
import styled from 'styled-components';
import Layout from "../layouts";
import Seo from "../components/seo"
import { Section, Container, SectionPageTitle } from "../components/Section"
import { Nodata, NodataSubTitle, NodataDesc, NodataContainer } from "../components/Nodata"
import SearchIcon from "../components/Icons/SearchIcon"
import SearchCloseIcon from "../components/Icons/SearchCloseIcon"
import { useFlexSearch } from 'react-use-flexsearch'

const SearchList = styled.div`
    margin:0;
`
const SearchItem = styled.div`
    padding:0;
`
const Search = styled.div`
    margin-bottom: 30px;
`
const SearchBody = styled.div`
	background-color: #EEF8FF;
	padding:20px;
	@media(min-width:1200px){
		padding:20px 25px;
	}
	@media(min-width:1600px){
		padding:20px 30px;
	}
`
const SearchTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-weight: 700;
	margin-bottom:10px;
	font-size: 18px;
	line-height: 30px;
    > a{
        color:#02044A;
        &:hover, &:focus, &:active{
            color:#4815D6;
        }
    }
	@media (min-width: 768px) {
		font-size: 20px;
		line-height: 36px;
	}
	@media (min-width: 992px) {
		font-size: 24px;
		line-height: 36px;
	}
`
const SearchDescription = styled.div`
	margin:0;
`
const SearchForm = styled.div`
  background-color: #EEF8FF;
  border-radius: 6px;
  width: 100%;
  max-width: 360px;
  margin:0 auto 30px auto;
  @media (min-width:1200px) {
    margin:0 auto 40px auto;
  }
  @media (min-width:1600px) {
    margin:0 auto 60px auto;
  }
  .input-icon{
    > .form-control{
        @media (min-width: 1200px){
            padding: 10px 15px;
        }
        @media (min-width: 1600px){
            padding: 15px;
        }

        &::placeholder {
            color:#004485;
        }
        &:focus,
        &:active {
            &::placeholder {
            color:#004485;
            }
        }
    }
    .icon {
        width:45px;
        > svg{
          fill:#4815D6;
          width:20px;
        }    
    }
    &.right {
        > .form-control{
            padding-right: 45px;
            padding-left: 15px;
        }
    }
  }
`


const SearchPage = ({ data, location }) => {
  const [query, setQuery] = useState(location?.state?.searchKey)
  const index = data.localSearchPages.index
  const store = data.localSearchPages.store

  const handleChange = (e) => {
    setQuery(e.target.value);
  }
  const results = useFlexSearch(query, index, store)
  return (
    <Layout hideSearch>
      <Seo title="Search Result" description="Search Result" />
      <Section ept="80px" epb="80px" xpt="40px" xpb="40px" pt="30px" pb="30px" bgColor="#fff" className="section-search" >
        <Container maxWidth="1170px">
          <SectionPageTitle textAlign="center">Search Results</SectionPageTitle>
          <SearchForm className='search-form'>
            <form>
              <div className="input-icon right">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search..."
                  id="search"
                  name="search"
                  value={query ? query : ""}
                  onChange={(e) => handleChange(e)}
                />
                <span className="icon"><SearchIcon /></span>
                <span className="icon" style={{ display: "none" }}><SearchCloseIcon /></span>
              </div>
            </form>
          </SearchForm>
          <SearchList className="search-list">
            {results.length > 0 ? results.map((item) =>
              <SearchItem>
                <Search className='media-card'>
                  <SearchBody className='media-card-body'>
                    <SearchTitle className='media-card-title'><Link to={item.path}>{item.title}</Link></SearchTitle>
                    <SearchDescription className='media-card-desc'>
                      <p>{item.description}</p>
                      <Link to={item.path}>Read More</Link>
                    </SearchDescription>
                  </SearchBody>
                </Search>
              </SearchItem>
            ) : (
              <Nodata>
                <NodataContainer>
                  <NodataSubTitle className='h3'>No Results Found</NodataSubTitle>
                  <NodataDesc><p>The page you requested could not be found. Try refining your search, or use the navigation above to locate the post.</p></NodataDesc>
                </NodataContainer>
              </Nodata>
            )}

          </SearchList>

        </Container>
      </Section>
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query SearchPageQuery{
    localSearchPages {
      index
      store
    }
  }
`